<template>
<div class="apCodeUpload-page-content winner-page__hero">
   <div class="main-block position-relative">
      <div class="container-xl py-lg-5">
        <div class="row" :class="winnerType == 'heti' ? 'winner-bg--heti':''">
            <div class="col-12 pt-lg-5 custom-padding">
                <div class="winner-bg">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="text-center winner-page--text">
                                <h1 class="text-black fw-900 mb-2">Gratulálunk!</h1>  
                                <p class="text-black fs-22 mb-2">nyereménye</p>            
                                <p class="text-black fw-bold fs-22">
                                    <span class="winner-page__hero__winning" v-if="winnerType == 'napi_pluss_maci'"> 1 db plüss POM-BÄR Maci</span>
                                    <span class="winner-page__hero__winning" v-if="winnerType == 'napi_picnic_kosar'"> 1 db POM-BÄR piknik szett</span>
                                    <span class="winner-page__hero__winning " v-if="winnerType == 'heti'">1 db 200.000 Ft értékű Hunguest Hotels Ajándékkártya</span>
                                    <span class="winner-page__hero__winning " v-if="winnerType == 'extra_spar'">1 db iPhone 15 Pro</span>
                                    <span class="winner-page__hero__winning tesco" style="text-align:left;" v-if="winnerType == 'extra_tesco'"><ul><li class="pb-2">1db MacBook Air 13” M3 vagy</li><li class="pb-2">1 db Játékkonzol + 1 db hozzávaló játék + VR szemüveg vagy</li><li class="pb-2">1 db 500.000 Ft értékkel feltöltött bankkártya</li></ul></span>
                                </p>
                            </div>
                        </div>   
                        <div class="col-12 col-lg-6 text-center px-0">
                            <img v-if="winnerType == 'napi_pluss_maci'" src="@/assets/imgs/winnings-maci.png" alt="napi nyememény" class="img-fluid"/>
                            <img v-if="winnerType == 'napi_picnic_kosar'" src="@/assets/imgs/winnings-napi.png" alt="napi nyememény" class="img-fluid"/>
                            <img v-if="winnerType == 'heti'" src="@/assets/imgs/winnings-heti.png" alt="heti nyeremény" class="img-fluid"/>
                            <img v-if="winnerType == 'extra_spar'" src="@/assets/imgs/winnings-spar.png" alt="heti nyeremény" class="img-fluid mb-5 pb-5" width="320"/>
                           <img v-if="winnerType == 'extra_tesco'" src="@/assets/imgs/winnings-tesco.png" alt="heti nyeremény" class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    </div>    
  </div>
  <!--<Ribbon/>-->
</div>
</template>

<script>
//import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        //Ribbon
    },
    props:['winnerType']
}
</script>